import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"

const Flyer = () => {
  const data = useStaticQuery(graphql`
  query {
    horizontal: file(relativePath: { eq: "banner-horizontal.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    vertical: file(relativePath: { eq: "banner-vertical.jpeg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
  `)

  return (
    <Link to="/">
      <Horizontal fluid={data.horizontal.childImageSharp.fluid} />
      <Vertical fluid={data.vertical.childImageSharp.fluid} />
    </Link>
  )
}

export default Flyer

const Horizontal = styled(Img)`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

const Vertical = styled(Img)`
  width: 100%;
  @media (min-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"

const Logo = () => {
  const data = useStaticQuery(graphql`
  query {
    logo: file(relativePath: { eq: "logo/urandinet-logotipo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
  `)

  return (
    <Link to="/">
      <StyledImg fluid={data.logo.childImageSharp.fluid} alt="UrandiNet - Provedor de Internet" />
    </Link>
  )
}

export default Logo

const StyledImg = styled(Img)`
  width: 300px;
`

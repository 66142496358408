import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { Section, Container } from "../global"

const Features = () => {

  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "social-network" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledSection id="serviços">
      <StyledContainer>
        <Subtitle>Serviços</Subtitle>
        <FeaturesGrid>
  
          <FeatureItem>
            <StyledImg fluid={data.file.childImageSharp.fluid} />
          </FeatureItem>
  
          <FeatureItem>
            <FeatureTitle>Converse. Assista. Curta.</FeatureTitle>
            <FeatureText>
              Acesse as suas redes sociais preferidas, converse com quem importa e assista vídeos e filmes*
              <p><small>*Assinaturas não inclusas</small></p>
            </FeatureText>
          </FeatureItem>
  
        </FeaturesGrid>
      </StyledContainer>
    </StyledSection>
  )
}

export default Features

const StyledContainer = styled(Container)``

const StyledSection = styled(Section)`
  padding-bottom: 0px;
`

const StyledImg = styled(Img)`
  width: 200px;
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 4fr 8fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 60px;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
`

const FeatureText = styled.p`
  text-align: left;
`

import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="planos">
    <StyledContainer>
      <Subtitle>Planos</Subtitle>
      <SectionTitle>Conheça nossos planos</SectionTitle>
      <FeaturesGrid>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 59,90
          </FeatureValue>
          <FeatureTitle>20 Mbps</FeatureTitle>
        </FeatureItem>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 69,90
          </FeatureValue>
          <FeatureTitle>30 Mbps</FeatureTitle>
        </FeatureItem>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 89,90
          </FeatureValue>
          <FeatureTitle>50 Mbps</FeatureTitle>
        </FeatureItem>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 99,90
          </FeatureValue>
          <FeatureTitle>100 Mbps</FeatureTitle>
        </FeatureItem>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 139,90
          </FeatureValue>
          <FeatureTitle>150 Mbps</FeatureTitle>
        </FeatureItem>

        <FeatureItem>
          <FeatureValue>
            <span className="currency">R$</span> 169,90
          </FeatureValue>
          <FeatureTitle>200 Mbps</FeatureTitle>
        </FeatureItem>

      </FeaturesGrid>

    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 900px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
    grid-column-gap: 20px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px;
  border-radius: 8px;

  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23), 0 -1px 6px rgba(0,0,0,0.1);
  transition: box-shadow ease-in-out 120ms, transform ease-in-out 120ms;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  
  &:hover {
    box-shadow: 0 3px 6px #B80000, 0 3px 6px #B80000;
    transform: translate(0, -3px);
  }
`

const FeatureItemRecommended = styled(FeatureItem)`
  height: 108%;
  margin-top: -6%;
  padding: 0;
  border: 5px solid ${props => props.theme.color.accent};
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

  @media (max-width: ${props => props.theme.screen.sm}) {
    height: 100%;
    margin-top: 0;
  }
`

const FeatureRecommendedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 10px 20px 10px;
  margin-bottom: auto;
`

const FeatureRecommended = styled.h4`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.white.regular};
  background-color: ${props => props.theme.color.accent};
  width: 100%;
  letter-spacing: 0px;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;

  .currency {
    ${props => props.theme.font_size.xsmall};
  }
`

const FeatureValue = styled.h4`
  ${props => props.theme.font_size.larger};
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 5px;

  .currency {
    ${props => props.theme.font_size.xxsmall};
  }
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
`

const FeatureText = styled.p`
  text-align: center;

  b {
    font-family: ${props => props.theme.font.bold};
  }
`

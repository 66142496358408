import React, { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Container } from "../global"
import Header from "./header"
import styled from "styled-components"

import Flyer from "./flyer"

export default class CarouselComponent extends Component {
  render() {
    return (
      <CustomCarousel
        showThumbs={false}
        style={{minHeight: "80px"}}
      >
        <div style={{minHeight: "80px"}}>
          <Container>
            <Flyer />
          </Container>
        </div>
        <div>
          <Container>
            <Header />
          </Container>
        </div>
      </CustomCarousel>
    )
  }
}

const CustomCarousel = styled(Carousel)`
  padding: 160px 0 0 0;

  .slide {
    background-color: #f1f1f1 !important;
  }

  .dot {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #222222;
  }

  .control-arrow {
    color: #d1d1d1 !important;

    @media (min-width: ${props => props.theme.screen.md}) {
      width: 150px !important;
    }

  }

  .control-prev.control-arrow:before {
    border-right: 8px solid #000000 !important;
    transform: scale(3);
  }
  
  .control-next.control-arrow:before {
    border-left: 8px solid #000000 !important;
    transform: scale(3);
  }
`
